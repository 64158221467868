<template>
  <div style="text-align: left">
    <article>
      <section>
        <div class="container mt-5">
          <div class="mb-3">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/ovalspace/os-admin/' }">Home</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/ovalspace/os-admin/categories' }">Categories</el-breadcrumb-item>
              <el-breadcrumb-item>Edit Room Category</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <h1
            class="mb-4"
            style="font-size: 1.1em; font-weight: 600;"
          >Edit Room Category</h1>
          <div v-loading="isLoading">
            <el-form
              :model="roomCategoryForm"
              :rules="categoryRules"
              ref="roomCategoryForm"
              class="demo-roomCategoryForm2"
            >
            <div class="row">
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Category Name</label>
                  <el-form-item prop="categoryName">
                    <el-input
                      v-model="roomCategoryForm.categoryName"
                      placeholder="Category Name"
                      :disabled="isUploadingFile"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Price Per Night</label>
                  <el-form-item prop="pricePerNight">
                    <el-input
                      v-model="roomCategoryForm.pricePerNight"
                      placeholder="UGX 20000"
                      :disabled="isUploadingFile"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Max Occupancy</label>
                  <el-form-item prop="maxOccupancy">
                    <el-input
                      v-model="roomCategoryForm.maxOccupancy"
                      placeholder="100"
                      :disabled="isUploadingFile"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Brief Description</label>
                  <el-form-item prop="description">
                    <el-input
                      v-model="roomCategoryForm.description"
                      placeholder="Category Brief Description"
                      :disabled="isUploadingFile"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <p>categoryImage: {{ selectedFile }}</p>
                  <label class="mt-3 mb-2">Category Main Image</label><br />
                  <el-form-item>
                    <el-upload
                      class="upload-demo"
                      drag
                      action="''"
                      :on-change="handlePreview"
                      :on-remove="handleRemove"
                      :file-list="fileLists"
                      :auto-upload="false"
                      multiple
                    >
                      <div v-if="!isUploadingFile">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          Drop file here or <em>click to upload</em>
                        </div>
                      </div>
                      <div v-else>
                        <el-progress
                          v-if="imageUploadingProgress != 100"
                          type="circle"
                          :color="progressColors"
                          :percentage="imageUploadingProgress"
                          :width="80"
                          :stroke-width="4"
                        ></el-progress>
                        <el-progress
                          v-else
                          type="circle"
                          :percentage="100"
                          status="success"
                          :width="80"
                          :stroke-width="4"
                        ></el-progress>
                        <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                          {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                        </div>
                      </div>
                      <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                    </el-upload>
                  </el-form-item>
                </div>
              </div>
              
              <div class="row">
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Category Slide Image One</label><br />
                  <el-form-item>
                    <el-upload class="upload-demo" drag action="''" :on-change="handlePreviewOne" :on-remove="handleRemoveOne"
                      :file-list="fileListsOne" :auto-upload="false">
                      <div v-if="!isUploadingFile">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          Drop file here or <em>click to upload</em>
                        </div>
                      </div>
                      <div v-else>
                        <el-progress v-if="imageUploadingProgress != 100" type="circle" :color="progressColors"
                          :percentage="imageUploadingProgress" :width="80" :stroke-width="4"></el-progress>
                        <el-progress v-else type="circle" :percentage="100" status="success" :width="80"
                          :stroke-width="4"></el-progress>
                        <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                          {{
                            imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                          }}
                        </div>
                      </div>
                      <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                    </el-upload>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Category Main Image Two</label><br />
                  <el-form-item>
                    <el-upload class="upload-demo" drag action="''" :on-change="handlePreviewTwo" :on-remove="handleRemoveTwo"
                      :file-list="fileListsTwo" :auto-upload="false">
                      <div v-if="!isUploadingFile">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          Drop file here or <em>click to upload</em>
                        </div>
                      </div>
                      <div v-else>
                        <el-progress v-if="imageUploadingProgress != 100" type="circle" :color="progressColors"
                          :percentage="imageUploadingProgress" :width="80" :stroke-width="4"></el-progress>
                        <el-progress v-else type="circle" :percentage="100" status="success" :width="80"
                          :stroke-width="4"></el-progress>
                        <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                          {{
                            imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                          }}
                        </div>
                      </div>
                      <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                    </el-upload>
                  </el-form-item>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Category Slide Image Three</label><br />
                  <el-form-item>
                    <el-upload class="upload-demo" drag action="''" :on-change="handlePreviewThree" :on-remove="handleRemoveThree"
                      :file-list="fileListsThree" :auto-upload="false">
                      <div v-if="!isUploadingFile">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          Drop file here or <em>click to upload</em>
                        </div>
                      </div>
                      <div v-else>
                        <el-progress v-if="imageUploadingProgress != 100" type="circle" :color="progressColors"
                          :percentage="imageUploadingProgress" :width="80" :stroke-width="4"></el-progress>
                        <el-progress v-else type="circle" :percentage="100" status="success" :width="80"
                          :stroke-width="4"></el-progress>
                        <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                          {{
                            imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                          }}
                        </div>
                      </div>
                    </el-upload>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Category Main Image Four</label><br />
                  <el-form-item>
                    <el-upload class="upload-demo" drag action="''" :on-change="handlePreviewFour" :on-remove="handleRemoveFour"
                      :file-list="fileListsFour" :auto-upload="false">
                      <div v-if="!isUploadingFile">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          Drop file here or <em>click to upload</em>
                        </div>
                      </div>
                      <div v-else>
                        <el-progress v-if="imageUploadingProgress != 100" type="circle" :color="progressColors"
                          :percentage="imageUploadingProgress" :width="80" :stroke-width="4"></el-progress>
                        <el-progress v-else type="circle" :percentage="100" status="success" :width="80"
                          :stroke-width="4"></el-progress>
                        <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                          {{
                            imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                          }}
                        </div>
                      </div>
                      <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                    </el-upload>
                  </el-form-item>
                </div>
              </div>

              <hr />
              <el-form-item class="text-center">
                <div
                  v-if="isUploadingFile"
                  class="d-flex justify-content-center"
                >
                  <ScaleOut />
                </div>
                <el-button
                  type="primary"
                  v-else
                  @click="editRoomCategory('roomCategoryForm')"
                >Edit Room Category</el-button>
              </el-form-item>
            </el-form>

            <div class="text-center">
              <div class="text-center">
                <small>© 2024, All rights reserved.</small>
              </div>
              <div class="text-center">
                <!--<small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>


<script>
import ScaleOut from "@/components/backoffice/scale-out-component.vue";
export default {
  components: {
    ScaleOut,
  },

  data() {
    return {
      addPackageModal: false,
      selectedFile: null,
      selectedFileOne: null,
      selectedFileTwo: null,
      selectedFileThree: null,
      selectedFileFour: null,
      selectedSlideFile: null,
      selectedSlideFiles: [],
      category: {},
      isUploadingFile: false,
      isLoading: false,
      value: "",
      content: "",
      loading: false,
      loadingError: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],

      
      roomCategoryForm: {
        categoryName: "",
        pricePerNight: "",
        maxOccupancy: "",
        description: "",
      },

      categoryRules: {
        categoryName: [
          {
            required: true,
            message: "Category Name is required",
            trigger: "blur",
          },
        ],
        pricePerNight: [
          {
            required: true,
            message: "Price Per Night is required",
            trigger: "blur",
          },
        ],
        maxOccupancy: [
          {
            required: true,
            message: "Max Occupancy is required",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Room Category Brief Description is required",
            trigger: "blur",
          },
        ],
      },

      fileLists: [],
      fileListsOne: [],
      fileListsTwo: [],
      fileListsThree: [],
      fileListsFour: [],
      fileLists_many: [],

      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
    };
  },

  mounted() {
    //Code here
    this.getRoomCategoryById();
  },

  methods: {
    handleClose(done) {
      done();
    },
    handlePreview(file) {
      this.fileLists = [file];
      this.selectedFile = file.raw;
      console.log(this.selectedFile);
    },

    handleChange(file, fileList) {
      this.fileLists_many = fileList;
      console.log(file.raw);
      console.log(this.fileLists_many);
      this.selectedSlideFile = file.raw;
      // this.selectedSlideFiles.push(this.selectedSlideFile);
      this.selectedSlideFiles = this.fileLists_many.map((item) => {
        return item.raw;
      });
      console.log(this.selectedSlideFiles);
    },

    handleRemove() {
      this.selectedFile = null;
    },

    handleRemoveAll(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.fileLists_many.filter((item) => {
        return item.url == `${file.url}`;
      });
    },

    handlePreviewOne(file) {
      this.fileListsOne = [file];
      this.selectedFileOne = file.raw;
      console.log(this.selectedFileOne);
    },

    handleRemoveOne() {
      this.selectedFileOne = null;
    },

    handlePreviewTwo(file) {
      this.fileListsTwo = [file];
      this.selectedFileTwo = file.raw;
      console.log(this.selectedFileTwo);
    },

    handleRemoveTwo() {
      this.selectedFileTwo = null;
    },

    handlePreviewThree(file) {
      this.fileListsThree = [file];
      this.selectedFileThree = file.raw;
      console.log(this.selectedFileThree);
    },

    handleRemoveThree() {
      this.selectedFileThree = null;
    },

    handlePreviewFour(file) {
      this.fileListsFour = [file];
      this.selectedFileFour = file.raw;
      console.log(this.selectedFileFour);
    },

    handleRemoveFour() {
      this.selectedFileFour = null;
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleExceed(files, fileList) {
      this.$message.warning(
        `The limit is 4, you selected ${
          files.length
        } files this time, add up to ${files.length + fileList.length} totally`
      );
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`);
    },
    
    handleDownload(file) {
      console.log(file);
    },

    async getRoomCategoryById() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `categories/${this.$route.params.categoryID}`
        );
        if (request.data.success && request.data.message == "ROOM_CATEGORY_FETCHED_SUCCESSFULLY") {
          this.category = request.data.category;
          this.roomCategoryForm.categoryName = this.category.categoryName;
          this.roomCategoryForm.pricePerNight = this.category.pricePerNight;
          this.roomCategoryForm.maxOccupancy = this.category.maxOccupancy;
          this.roomCategoryForm.description = this.category.description;
          this.selectedFile = this.category.categoryImage;
          this.selectedFileOne = this.category.slideRoomImages[0].path;
          this.selectedFileTwo = this.category.slideRoomImages[1].path;
          this.selectedFileThree = this.category.slideRoomImages[2].path;
          this.selectedFileFour = this.category.slideRoomImages[3].path;
          // this.selectedSlideFiles = this.category.slideRoomImages.map(
          //   (item) => {
          //     return item.path;
          //   }
          // );
          this.fileLists = [
            {
              name: "category_cover_image",
              url: `${this.category.categoryImage}`,
            },
          ];

          this.fileListsOne = [{
              name: "slide_image_one",
              url: this.selectedSlideFiles[0],
            },
          ];

          this.fileListsTwo = [{
              name: "slide_image_two",
              url: `${this.selectedSlideFiles[1]}`,
            },
          ];

          this.fileListsThree = [{
              name: "slide_image_three",
              url: `${this.selectedSlideFiles[2]}`,
            },
          ];

          this.fileListsFour = [{
              name: "slide_image_four",
              url: `${this.selectedSlideFiles[3]}`,
            },
          ];
          
          this.fileLists_many = this.category.slideRoomImages.map(
            (item, index) => {
              return {
                name: `slide_image_${index}`,
                url: `${item.path}`,
              };
            }
          );
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        return this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch Category now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async editRoomCategory(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          alert(this.selectedFile)
          this.isUploadingFile = true;
          if (this.selectedFile == null) {
            this.$notify({
              title: "Empty",
              message: "Category Cover photo is empty",
              type: "warning",
            });
          }
          
          if (this.selectedFileOne == null) {
            return this.$notify({
              title: "Empty",
              message: "Category photo One is empty",
              type: "warning",
            });
          }

          if (this.selectedFileTwo == null) {
            return this.$notify({
              title: "Empty",
              message: "Category photo Two is empty",
              type: "warning",
            });
          }

          if (this.selectedFileThree == null) {
            return this.$notify({
              title: "Empty",
              message: "Category photo Three is empty",
              type: "warning",
            });
          }

          if (this.selectedFileFour == null) {
            return this.$notify({
              title: "Empty",
              message: "Category photo Four is empty",
              type: "warning",
            });
          }

          try {
            this.isUploadingFile = true;
            const formData = new FormData();
            formData.append("categoryName", this.roomCategoryForm.categoryName);
            formData.append("pricePerNight", this.roomCategoryForm.pricePerNight);
            formData.append("maxOccupancy", this.roomCategoryForm.maxOccupancy);
            formData.append("description", this.roomCategoryForm.description);
            formData.append("categoryImage", this.selectedFile);
            
            formData.append("slideRoomImages0", this.selectedFileOne);
            formData.append("slideRoomImages1", this.selectedFileTwo);
            formData.append("slideRoomImages2", this.selectedFileThree);
            formData.append("slideRoomImages3", this.selectedFileFour);

            let request = await this.$http.patch(
              `categories/${this.$route.params.categoryID}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                },
              }
            );
            if (
              request.data.success && request.data.message == "ROOM_CATEGORY_UPDATED_SUCCESSFULLY"
            ) {
              this.addPackageModal = false;
              this.$router.push({
                path: `/ovalspace/os-admin/categories`,
              });
              return this.$notify({
                title: "Success",
                message: "Category Updated Successfully",
                type: "success",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isUploadingFile = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isUploadingFile = false;
            return this.$notify({
              title: "Upload Failed",
              message: "Unable to Upload profile picture now, Please try again",
              type: "error",
            });
          } finally {
            this.isUploadingFile = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.addBtn {
  background-color: green;
  color: white;
}
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.safari_dialogs .el-dialog {
  width: 60%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .safari_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .safari_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
